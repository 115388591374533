import Button from "@material-ui/core/Button"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import { useContext, useState } from "react"
import SiteContext from "../../contexts/SiteContext"
import LoginContext from "./LoginContext"

import IconButton from "@material-ui/core/IconButton"

import TextField from "@material-ui/core/TextField"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import { useTextos } from "../../contexts/TextosContext"
import useCustomHistory from "../../hooks/useCustomHistory"
import { identificarEstabelecimentoSelecionado } from "../../services/functions"
import "./styles.css"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
}))

export default function OutrasOpcoes(props) {
    const {
        aplicativoDados,

        estabelecimentoAtual,
        setEstabelecimentoAtual,
    } = useContext(SiteContext)

    const textos = useTextos()
    const { setOutrasOpcoes, outrasOpcoes, emailTelefone, setEmailTelefone, enviarCodigoTelefone } =
        useContext(LoginContext)

    const [loginWhatsapp, setLoginWhatsapp] = useState(false)

    const history = useCustomHistory()

    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Dialog
            open={outrasOpcoes}
            fullScreen={fullScreen}
            aria-labelledby="outrasOpcoes"
            onClose={() => {
                setOutrasOpcoes(false)
                setLoginWhatsapp(false)
            }}
        >
            {!fullScreen ? <img src={aplicativoDados.urlLogo} className="imgModal" /> : null}

            <DialogTitle id="outrasOpcoes">
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setLoginWhatsapp(false)
                        setOutrasOpcoes(false)
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                {textos.outrasOpcoesLogin}
            </DialogTitle>

            <DialogContent>
                {!aplicativoDados.loginApenasTelefone ? (
                    <Button
                        type="button"
                        fullWidth
                        onClick={() => {
                            const [url, params] =
                                identificarEstabelecimentoSelecionado(aplicativoDados)
                            history.push(`/cadastro` + url, params)
                        }}
                        variant="contained"
                        style={{
                            margin: "0 0 1em 0",
                            background: `${aplicativoDados?.corSitePrimaria}`,
                            color: "white",
                        }}
                    >
                        {textos.cadastrarUsuario}
                    </Button>
                ) : null}

                {estabelecimentoAtual?.possuiPedidoMesa ? (
                    <Button
                        type="button"
                        fullWidth
                        onClick={() => {
                            history.push("conectar-mesa")
                        }}
                        variant="contained"
                        style={{ margin: "0 0 1em 0", background: "#3c3c3c", color: "white" }}
                    >
                        <AirlineSeatReclineNormalIcon /> {textos.conectarComMesa}
                    </Button>
                ) : null}

                {aplicativoDados.permiteLoginTelefone || aplicativoDados.loginApenasTelefone ? (
                    <Button
                        type="button"
                        fullWidth
                        onClick={() => {
                            setLoginWhatsapp(true)
                        }}
                        variant="contained"
                        style={{ margin: "0 0 1em 0", background: "#24cc63", color: "white" }}
                    >
                        <WhatsAppIcon /> {textos.conectarComWPP}
                    </Button>
                ) : null}
                {aplicativoDados.possuiDelivery === true ? (
                    <Button
                        type="button"
                        fullWidth
                        onClick={() => {
                            if (estabelecimentoAtual?.id) {
                                history.push("/delivery")
                            } else if (aplicativoDados.estabelecimentos.length > 1) {
                                history.push("/lojas")
                            } else {
                                setEstabelecimentoAtual(aplicativoDados.estabelecimentos[0])
                                window.setTimeout(function () {
                                    history.push("/delivery")
                                }, 1000)
                            }
                        }}
                        style={{ margin: "0 0 1em 0" }}
                    >
                        {aplicativoDados.permitirContinuarSemCadastro
                            ? textos.naoQueroCadastrar
                            : textos.verCardapio}
                    </Button>
                ) : null}

                {loginWhatsapp ? (
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            enviarCodigoTelefone()
                            setOutrasOpcoes(false)
                            setLoginWhatsapp(false)
                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={e => {
                                setEmailTelefone(e.target.value.replace(/\D/gim, ""))
                            }}
                            value={emailTelefone.replace(/\D/gim, "")}
                            label={textos.whatsapp}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {textos.continuar}
                        </Button>
                    </form>
                ) : null}
            </DialogContent>

            <DialogActions></DialogActions>
        </Dialog>
    )
}
